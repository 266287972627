<template>
	<el-dialog
		:visible="showDialogRentalOptions"
		@open="create"
		width="35%"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
	>
		<!-- <v-layout row wrap mb-6>
			<v-flex lg12 xl12 xs12 sm12 md12 d-flex justify-center>
				<el-alert
					title="El pago se ha realizado de manera satisfactoria"
					type="info"
					center
					:closable="false"
					show-icon
				></el-alert>
			</v-flex>
		</v-layout> -->
		<v-layout row wrap>
			<v-flex lg12 xl12 xs12 sm12 md12 d-flex justify-center>
				<h3>{{ form.document_type }}</h3>
			</v-flex>
			<v-flex lg12 xl12 xs12 sm12 md12 d-flex justify-center>
				<h4>{{ form.number }}</h4>
			</v-flex>
			<v-flex lg12 xl12 xs12 sm12 md12 d-flex justify-center>
				<h4>TOTAL: {{ form.currency }} {{ form.total }}</h4>
			</v-flex>
		</v-layout>
		<v-row>
			<v-col cols="12" xl="4" lg="4" offset-xl="4" offset-lg="4">
				<el-button type="primary" class="btn-block" block @click="clickPrint()">
					Imprimir ticket
				</el-button>
			</v-col>
		</v-row>
		
		
		<span slot="footer" class="dialog-footer">
			<v-btn color="error" :to="{ name: 'Rent' }">Ir a recepción</v-btn>
		</span>
	</el-dialog>
</template>

<script>
import printJS from 'print-js';

export default {
	props: [
		'showDialogRentalOptions',
		'recordId',
	],
	data() {
		return {
			loading: false,
			resource: 'rentals',
			errors: {},
			form: {},
			company: {},
		};
	},
	created() {},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				email: null,
				external_id: null,
				number: null,
				id: null,
			};
		},
		async create() {
			await this.initForm();
			await this.$http.get(`/${this.resource}/record2/${this.recordId}`).then((response) => {
				this.form = response.data.data;
			});
		},
		clickPrint() {

			printJS(`${this.form.print}`);
		},
		clickNewSale() {
			this.initForm();
			this.$emit('update:showDialogRentalOptions', false);
			this.$router.push({ name: "Rent" });
		},
	},
};
</script>
